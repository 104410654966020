<template>
  <TagInput
    v-bind="$props"
    :min="validatorOptions.tags.min"
    :max="validatorOptions.tags.max"
    :maxLength="validatorOptions.tags.maxlength"
    :isValid="valid"
    :name="name"
    :locale="language"
    ref="tagInput"
  />
</template>

<script>
import compositionValidator from '@/api/validators/composition/compositionValidator';
import TagInput from './TagInput.vue';
import compositionHelper from '@/composition/compositionHelper';

export default {
  name: 'CompositionTagInput',
  components: {
    TagInput,
  },
  props: {
    validate: {
      type: Boolean,
    },
    composition: {
      required: false,
    },
    language: {
      type: String,
    },
  },
  data() {
    return {
      validatorOptions: compositionValidator.getDefaultOptions(),
    };
  },
  computed: {
    name() {
      return this.composition
        ? compositionHelper.getMetaDataPart({
            composition: this.composition,
            part: 'name',
            language: this.language,
          })?.value
        : null;
    },
    valid() {
      if (!this.composition || !this.validate) {
        return true;
      }
      return compositionValidator.validateTags(this.composition, {
        language: this.language,
      });
    },
    hasError() {
      return this.$refs.tagInput.hasError;
    },
  },
};
</script>

<template>
  <a @click.prevent="scrollToTarget" :class="{ targetIsIntersecting, error }">
    <Icon icon="exclamation" class="error-icon" />

    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'PublishingConfigMenuItem',
  components: {},
  props: {
    targetRef: {
      required: false,
    },
    error: {
      type: Boolean,
    },
  },
  data() {
    return {
      observer: null,
      targetIsIntersecting: false,
    };
  },
  watch: {
    targetRef: {
      handler(val) {
        if (val) {
          this.initObserver();
        } else {
          this.observer?.destroy();
        }
      },
    },
  },
  unmounted() {
    this.observer?.disconnect();
  },
  computed: {},
  async created() {},
  methods: {
    initObserver() {
      if (this.targetRef && window.IntersectionObserver) {
        this.observer = new IntersectionObserver(
          ([{ isIntersecting }]) => (this.targetIsIntersecting = isIntersecting)
        );
        this.observer.observe(this.targetRef);
      }
    },
    scrollToTarget() {
      if (this.targetRef) {
        this.targetRef.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

a {
  display: flex;
  align-items: center;

  &.targetIsIntersecting {
    text-shadow: -0.03ex 0 0 currentColor, 0.03ex 0 0 currentColor;
    -webkit-text-stroke-width: 0.04ex;
  }
  &.targetIsIntersecting ~ &.targetIsIntersecting {
    text-shadow: none;
    -webkit-text-stroke-width: 0ex;
  }

  &.error {
    color: $pa-color-red;

    .error-icon {
      display: inline-block;
    }
  }

  .error-icon {
    display: none;
    margin: 0 0 0 -16px;
  }
}
</style>

<template>
  <div class="publishing-detail-wrapper page-wrapper" v-if="initialized">
    <nav class="page-header navbar-secondary navbar-default">
      <div class="nav">
        <button class="link link-main icon-link" @click="back">
          <Icon icon="left" /> {{ $t('GENERAL.BACK') }}
        </button>
        <div class="heading-wrapper">
          <h2>Create Products</h2>
        </div>
        <div class="actions">
          <a
            v-if="pubCLink"
            class="btn btn-light"
            :href="pubCLink"
            target="_blank"
          >
            <Icon icon="outside" /> PubC
          </a>
          <button
            class="btn btn-light-danger delete-btn"
            @click="triggerDeleteComposition"
          >
            <Icon icon="bin" />
          </button>
          <VDropdown>
            <button href="#" class="btn btn-light info-btn" @click.prevent>
              <Icon icon="info-circle" />
            </button>
            <template #popper>
              <CompositionInfos :composition="composition" />
            </template>
          </VDropdown>

          <ProgressButton
            class="btn btn-primary save-btn"
            :onClick="save"
            :label="saveLabel"
            :disabled="!dirty"
          />
        </div>
      </div>
    </nav>
    <div class="page-content">
      <div class="overflow-y">
        <div class="publishing-detail-content">
          <div class="configuration-menu">
            <div class="configuration-menu-content">
              <PublishingConfigMenuItem
                :targetRef="refsReady ? $refs.publishShop : null"
                v-if="hasRegisteredShops"
              >
                Publish to SpreadShop
              </PublishingConfigMenuItem>
              <PublishingConfigMenuItem
                :targetRef="refsReady ? $refs.publishMp : null"
                v-if="hasMarketplace"
              >
                Publish to Spreadshirt
              </PublishingConfigMenuItem>
              <PublishingConfigMenuItem
                :targetRef="refsReady ? $refs.details : null"
                :error="$refs.metaData && $refs.metaData.hasError"
              >
                Details
              </PublishingConfigMenuItem>
              <PublishingConfigMenuItem
                :targetRef="refsReady ? $refs.products : null"
              >
                Products
              </PublishingConfigMenuItem>
            </div>
          </div>
          <div class="configuration">
            <div>
              <ConfigSection
                heading="Products"
                subheading="Details about your products"
              >
                <PublishingProducts />
              </ConfigSection>
            </div>
            <div>
              <div class="scroll-target" ref="products"></div>

              <ConfigSection heading="Details">
                <PublishingMetaData
                  :validate="strictValidation && shouldValidate"
                  ref="metaData"
                />
              </ConfigSection>
              <div class="scroll-target" ref="details"></div>
            </div>
            <div v-if="hasMarketplace">
              <ConfigSection heading="Publish to Spreadshirt">
                <PublishingMpConfig />
              </ConfigSection>
            </div>
            <div class="scroll-target" ref="publishMp"></div>
            <div v-if="hasRegisteredShops">
              <ConfigSection heading="Publish to Spreadshop">
                <PublishingShopConfig />
              </ConfigSection>
            </div>
            <div class="scroll-target" ref="publishShop"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loader" v-else>
    <LoadingHeart />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import LoadingHeart from '@/LoadingHeart/LoadingHeart.vue';
import ProgressButton from 'src/app/commons/btnProgress/ProgressButton.vue';
import ConfigSection from 'src/app/components/publishing/ConfigSection.vue';
import PublishingMpConfig from './PublishingMpConfig.vue';
import PublishingShopConfig from './PublishingShopConfig.vue';
import PublishingProducts from './PublishingProducts.vue';
import PublishingMetaData from './PublishingMetaData.vue';
import PublishingConfigMenuItem from './PublishingConfigMenuItem.vue';
import CompositionInfos from './CompositionInfos.vue';
import ConfirmDialog from '@/dialogs/confirm/ConfirmDialog.vue';
import dialogService from '@/dialogs/wrapper/dialogService';
import unsavedChangesService from '@/unsavedChangesService/unsavedChangesService';

export default {
  name: 'PublishingDetail',
  components: {
    LoadingHeart,
    ProgressButton,
    ConfigSection,
    PublishingMpConfig,
    PublishingShopConfig,
    PublishingProducts,
    PublishingMetaData,
    PublishingConfigMenuItem,
    CompositionInfos,
  },
  data() {
    return {
      initialized: false,
      displayMenu: true,
      refsReady: false,
      strictValidation: false,
    };
  },
  computed: {
    ...mapState({
      composition: (s) => s.composition.current,
    }),
    ...mapGetters({
      dirty: 'composition/dirty',
      isPublic: 'composition/isPublic',
      shouldValidate: 'composition/shouldValidate',
      isAdmin: 'account/isAdmin',
      hasMarketplace: 'user/hasMarketplace',
      hasRegisteredShops: 'publishingCore/hasRegisteredShops',
    }),
    saveLabel() {
      return `Save ${this.isPublic ? '& Publish' : ''}`;
    },
    pubCLink() {
      if (!this.isAdmin) {
        return null;
      }

      return `https://pubc-insight-${
        SPRD.PLATFORM === 'NA' ? 'na' : 'eu'
      }.sprd.net/compositions?compositionId=${this.composition.id}`;
    },
  },
  async created() {
    await this.$store.state.publishingCore.onDataLoaded;

    await this.fetchComposition(this.$route.params.compositionId);
    this.initialized = true;

    setTimeout(() => {
      this.refsReady = true;
    }, 200);

    unsavedChangesService.registerComponent({
      check: () => this.dirty,
      save: () => {
        return this.save();
      },
      discard: () => {
        this.resetToOriginal();
      },
    });
  },
  methods: {
    ...mapActions({
      fetchComposition: 'composition/fetchComposition',
      updateComposition: 'composition/updateComposition',
      deleteComposition: 'compositions/deleteComposition',
    }),
    ...mapMutations({
      resetToOriginal: 'composition/resetToOriginal',
    }),
    async triggerDeleteComposition() {
      try {
        await dialogService.openDialog(ConfirmDialog, {
          heading: 'GENERAL.WARNING',
          description: 'DESIGNS.DELETE.SINGLE_WARNING',
          cancel: 'GENERAL.CANCEL',
          confirm: 'GENERAL.DELETE',
          danger: true,
        });
        await this.deleteComposition(this.composition.id);
        this.$router.push({
          name: 'partnerarea.publishing',
          params: { restoreState: true },
        });
      } catch (error) {
        // nothing
      }
    },
    back() {
      this.$router.push({
        name: 'partnerarea.publishing',
        params: { restoreState: true },
      });
    },
    async save() {
      this.strictValidation = true;
      await this.updateComposition();
      this.strictValidation = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/styleguide/colors';
@import 'src/scss/styleguide/type';

.page-header {
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  border: none;
}

.nav {
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  align-items: center;
  padding: 0 24px;

  .actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .heading-wrapper {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0;
    }
  }

  a.btn {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .btn {
    height: 36px;
  }
}

.publishing-detail-wrapper {
  position: relative;
  display: flex;

  .overflow-y {
    width: 100%;
  }
}

.publishing-detail-content {
  width: 100%;
  padding: 0 24px;
  display: flex;
  gap: 80px;
}

.configuration-menu {
  .configuration-menu-content {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column-reverse;
    padding: 24px 0 0 0;
  }
  a {
    margin: 0 0 16px 0;

    &.active {
      font-weight: bold;
    }
  }
}

.configuration {
  flex: 1;
  padding: 24px 0 0 0;

  & > div {
    background-color: $grey0;
    border: 1px solid $grey10;
    border-radius: 4px;
    margin-bottom: 24px;
    padding: 24px;
    position: relative;

    &.scroll-target {
      margin: 0;
      padding: 0;
      visibility: hidden;
    }
  }
}
</style>

<style lang="scss">
#application.state-partnerarea-publishingDetail {
  overflow-y: initial;

  .navbar-main {
    display: none;
  }

  #main-content {
    overflow: initial;
    margin-left: 0;
  }
}
</style>

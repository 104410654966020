<template>
  <textarea
    v-bind="{ ...$props, ...$attrs }"
    :value="props.modelValue"
    @input="onInput"
  />
</template>

<script setup>
import debounce from 'lodash/debounce';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: true,
  },
  wait: {
    type: Number,
    default: 200,
  },
});
const onInput = debounce((evt) => {
  emit('update:modelValue', evt.target.value);
}, props.wait);
</script>
